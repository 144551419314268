interface ITimeRemaining {
    total: number,
    days: number,
    hours: number,
    minutes: number,
    seconds: number
}
const useCountdownBlock = (blockElement: HTMLElement, countToDateTime: Date): void => {
    const daysTensElement = blockElement.querySelector('.flip-card--days-tens') as HTMLSpanElement;
    const daysOnesElement = blockElement.querySelector('.flip-card--days-ones') as HTMLSpanElement;
    const hoursTensElement = blockElement.querySelector('.flip-card--hours-tens') as HTMLSpanElement;
    const hoursOnesElement = blockElement.querySelector('.flip-card--hours-ones') as HTMLSpanElement;
    const minutesTensElement = blockElement.querySelector('.flip-card--minutes-tens') as HTMLSpanElement;
    const minutesOnesElement = blockElement.querySelector('.flip-card--minutes-ones') as HTMLSpanElement;
    const secondsTensElement = blockElement.querySelector('.flip-card--seconds-tens') as HTMLSpanElement;
    const secondsOnesElement = blockElement.querySelector('.flip-card--seconds-ones') as HTMLSpanElement;
    
    const getTimeRemaining = (): ITimeRemaining => {
        const currentDateTime = new Date();
        const total = (countToDateTime.getTime() - currentDateTime.getTime()) / 1000;
        const days = Math.floor(total / (60 * 60 * 24));
        const hours = Math.floor(total / (60 * 60) % 24);
        const minutes = Math.floor((total / 60)) % 60;
        const seconds = Math.floor(total % 60);

        return {
            total,
            days,
            hours,
            minutes,
            seconds
        };
    }
    
    const updateFlipCard = (element: HTMLSpanElement, newNumber: number): void => {
        const bottom = element.querySelector('.flip-card__bottom') as HTMLSpanElement;
        const bottomBack = element.querySelector('.flip-card__bottom-back') as HTMLSpanElement;
        const initialNumber = parseInt(bottom.textContent as string);
       
        if (newNumber === initialNumber) {
            return;
        }
        
        const top = document.createElement('span');
        const topBack = document.createElement('span');
        
        top.classList.add('flip-card__top');
        top.setAttribute('aria-hidden', 'true');
        topBack.classList.add('flip-card__top-back');
        topBack.setAttribute('aria-hidden', 'true');
        top.textContent = initialNumber.toString();
        topBack.innerHTML = `<span>${initialNumber}</span>`;
        bottom.textContent = initialNumber.toString();
        bottomBack.innerHTML = `<span>${newNumber}</span>`;
        
        top.addEventListener('animationend', () => {
            top.remove();
        });
        
        topBack.addEventListener('animationstart', () => {
            topBack.innerHTML = `<span>${newNumber}</span>`;
        });

        topBack.addEventListener('animationend', () => {
            bottom.textContent = newNumber.toString();
            topBack.remove();
        });
        
        element.prepend(top, topBack);
    }
    
    const updateTimer = (timeRemaining: ITimeRemaining): void => {
        if (timeRemaining.total < 0) {
            timeRemaining.days = 0;
            timeRemaining.hours = 0;
            timeRemaining.minutes = 0;
            timeRemaining.seconds = 0;
        }

        updateFlipCard(daysTensElement, Math.floor(timeRemaining.days / 10));
        updateFlipCard(daysOnesElement, timeRemaining.days % 10);
        updateFlipCard(hoursTensElement, Math.floor(timeRemaining.hours / 10));
        updateFlipCard(hoursOnesElement, timeRemaining.hours % 10);
        updateFlipCard(minutesTensElement, Math.floor(timeRemaining.minutes / 10));
        updateFlipCard(minutesOnesElement, timeRemaining.minutes % 10);
        updateFlipCard(secondsTensElement, Math.floor(timeRemaining.seconds / 10));
        updateFlipCard(secondsOnesElement, timeRemaining.seconds % 10);
    }
    
    const initialize = (): void => {
        const timeInterval = setInterval(() => {
            const timeRemaining = getTimeRemaining();
            updateTimer(timeRemaining);

            if (timeRemaining.total <= 0) {
                clearInterval(timeInterval);
            }
        }, 1000);
    }
    
    initialize();
};

document.addEventListener('DOMContentLoaded', () => {
    const countdownBlockElements = document.querySelectorAll('.countdown-block') as NodeListOf<HTMLElement>;

    if (countdownBlockElements.length > 0) {
        countdownBlockElements.forEach((blockElement) => {
            const contentElement: HTMLDivElement | null = blockElement.querySelector('.countdown-block__content');
            if (!contentElement) {
                return;
            }

            const countToDateTime = new Date(contentElement.dataset.countToDateTime as string);
            
            useCountdownBlock(blockElement, countToDateTime);
        })
    }
});
